import React, { useState, useEffect } from 'react';

import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import { Container } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from "react-router-dom";
import utils from '@Components/utils';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import api from '@Services/api';





const CountdownTimer = () => {
    const [timeRemaining, setTimeRemaining] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const getTimeRemaining = () => {
            const now = new Date();
            const etMidnight = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));
            etMidnight.setHours(24, 0, 0, 0);
            const timeDiff = etMidnight.getTime() - now.getTime();

            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            return { hours, minutes, seconds };
        };

        const updateCountdown = () => {
            const timer = getTimeRemaining();
            setTimeRemaining(timer);
        };

        const timerInterval = setInterval(updateCountdown, 1000);

        // Initial call to display the countdown immediately
        updateCountdown();

        // Clean up the interval on component unmount
        return () => clearInterval(timerInterval);
    }, []);

    const formatTime = (time) => time.toString().padStart(2, '0');

    return (
        <div
            className="p-1 pb-0"
        >
            <div style={{ fontSize: 14, width: 120, backgroundColor: "#f0f0f0", padding: '5px 10px', borderRadius: 5 }}>
                🕓
                &nbsp;
                {timeRemaining.hours === 0 && timeRemaining.minutes === 0 && timeRemaining.seconds === 0 ? (
                    "Day ended!"
                ) : (
                    `${formatTime(timeRemaining.hours)}:${formatTime(timeRemaining.minutes)}:${formatTime(timeRemaining.seconds)}`
                )}
            </div>
        </div >
    );
};

function Ofertas() {
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars
    const { t } = useTranslation(); //eslint-disable-line no-unused-vars
    let token = useParams().token;
    let offer_code = useParams().offer_code;


    const [ofertas, setOfertas] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        api.ofertas.fetch({
            token: token || "",
            offer_code: offer_code || "",
            country_code: cookies.alani360
        }).then(setOfertas).then(() => setLoading(false));
    }, []); //eslint-disable-line

    return <>
        {!!token ?
            <HeaderAccount />
            :
            <Header />
        }
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Ofertas</title>
            </Helmet>
        </HelmetProvider>

        <Container className="p-3 p-b0" style={{ maxWidth: 550, }}>


            {
                ofertas?.title ?
                    <div
                        className='text-center'
                    >
                        <b>🤑 {ofertas.title}</b>
                        <p>
                            {ofertas.description}
                        </p>
                    </div>
                    :
                    <div
                        className='text-center'
                    >
                        <b>🤑 Ofertas Best Buy powered by ALANI360</b>

                        {ofertas?.products &&
                            <p >
                                Estas ofertas expiran cuando el reloj marca cero.
                                <br />
                                Para verlas diariamente escribe “Ofertas del Día” en WhatsApp.
                                <br />
                                Todos los días temenos nuevas ofertas.
                            </p>
                        }
                    </div>
            }




            {loading ? (
                <div className="d-flex justify-content-center my-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (

                !ofertas?.products?.length ?
                    <div
                        className="alert alert-warning"
                        style={{
                            marginTop: 15,
                            textAlign: 'center'
                        }}
                    >
                        <b>Estas ofertas no están disponibles en este momento</b>
                    </div>
                    :

                    <div className="mt-3">
                        {ofertas?.products?.map((oferta, index) => (
                            <div key={index} className="card mb-3">
                                <div className="row g-0">
                                    <div  >
                                        <div className="card-body">
                                            <img src={oferta.image} className="p-2 img-fluid rounded-start" style={{ height: 130, float: 'left', marginRight: '15px' }} alt={oferta.name} />

                                            <h5 className="card-title">{oferta.name}</h5>

                                            <p
                                                className="card-text"
                                                dangerouslySetInnerHTML={{ __html: oferta.longDescription }} />



                                            <div
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >

                                                    {oferta.allInPriceRegular && (
                                                        <span
                                                            style={{
                                                                textDecoration: 'line-through',

                                                                marginBottom: '5px'
                                                            }}
                                                        >
                                                            {utils.formatCurrency(oferta.allInPriceRegular, oferta.currency_code)}
                                                        </span>
                                                    )}

                                                    <a
                                                        style={{
                                                            fontSize: 16,
                                                            textAlign: 'center',
                                                            padding: '5px 20px',

                                                            borderRadius: 5
                                                        }}
                                                        rel="noreferrer"
                                                        target='_blank'
                                                        href={`/b/${token}/${oferta.sku}`}
                                                        className={"btn" + ((!oferta.onlineAvailability || oferta.excluded) ? " btn-danger disabled" : " btn-success")}
                                                    >

                                                        {utils.formatCurrency(oferta.allInPrice, oferta.currency_code)}
                                                    </a>
                                                    {
                                                        !offer_code
                                                        && oferta.onlineAvailability
                                                        && oferta.excluded == 0
                                                        && <CountdownTimer />
                                                    }

                                                </div>

                                                <div>
                                                    <a
                                                        rel="noreferrer"
                                                        target='_blank'
                                                        href={`/b/${token}/${oferta.sku}`}
                                                        className="btn btn-primary"
                                                        style={{ width: 150 }}
                                                    >
                                                        Detalles
                                                    </a>
                                                </div>

                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        ))}
                    </div>

            )}



            {
                !loading &&

                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}}`}
                >
                    <img
                        style={{
                            width: 25,
                            marginRight: 10

                        }}
                        alt={cookies.alani360}

                        src={`/images/flags/${cookies.alani360}.png`}
                    />
                    {utils.getWhatsappNumber(cookies.alani360)}
                </a>
            }

        </Container>













    </>


}

export default Ofertas;
