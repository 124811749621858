//input form to enter the token

import React, { useState } from 'react';

import api from '@Services/api';
import { useCookies } from 'react-cookie';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
    Spinner,
    ToastContainer,
    Toast,
} from 'react-bootstrap';
import utils from '@Components/utils';
import { useTranslation, } from 'react-i18next';
const ExpiredToken = ({
    redirect,
    newToken,
    concurso,
    after_send_token
}) => {

    const { t } = useTranslation();
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars
    const [showToast, setShowToast] = useState({ bg: 'success', show: false, message: '' });

    const [phone, setPhone] = useState("+" + (cookies.alani360info?.phone || utils.getCountryDialingCode(cookies.alani360).replace("+", ""))); // eslint-disable-line no-unused-vars
    const [fetching, setFetching] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState(cookies.alani360); // eslint-disable-line no-unused-vars
    const [linkSent, setLinkSent] = useState(false);

    const [message, setMessage] = useState('');

    const fnReactivate = (redirectToWhatsapp) => {


        if (
            !phone
            ||
            phone.length < 10
            ||
            phone === '+'
        ) {
            setShowToast({ bg: 'danger', show: true, message: 'Por favor ingresa tu número de celular' });
            return;
        }

        let payload = {
            phone,
            country_code: selectedCountry,
            redirect: redirect,
            concurso: (concurso || 0)
        }


        setFetching(true);


        api.reactivate({
            method: 'get',
            payload
        }).then(res => {
            setFetching(false);

            if (res.success) {

                if (after_send_token) {
                    after_send_token();
                } else {

                    if (redirectToWhatsapp) {

                        window.open(
                            'https://wa.me/' + utils.getWhatsappNumber(selectedCountry),
                            '_blank'
                        );
                    }
                    setLinkSent(true);
                    if (res.request_country !== res.account_country) {
                        setMessage(`Tu cuenta está registrada en ${utils.countryName(res.account_country)} ${utils.countryFlag(res.account_country)}`);
                        setCookie('alani360', res.account_country, { path: '/' });

                    }
                }
            } else {
                setShowToast({ bg: 'danger', show: true, message: res.message });

            }

        });
    }


    if (linkSent) {
        return (
            <div
                className='d-flex flex-column justify-content-center align-items-center 
                pt-3 mt-3
                pb-3 mb-3
                '
                style={{
                    background: 'green',
                    textAlign: 'center',

                    margin: 'auto',
                    borderRadius: 5,
                }}
            >

                <div
                    className='text-white'
                >
                    {message !== '' &&
                        <div
                            style={{
                                padding: 5
                            }}
                        >
                            {message}
                        </div>
                    }
                    Revisa tu WhatsApp para continuar
                    <br />
                    <br />
                    <div
                        style={{
                            backgroundColor: '#fff',
                            borderRadius: 5,
                            padding: 5,
                            maxWidth: 300
                        }}

                    >
                        <a

                            target="_blank"
                            rel="noreferrer"
                            style={{ color: 'red', margin: 10 }}
                            href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=${utils.urlencode('Mi Cuenta')}`}
                        >
                            Si no has recibido el mensaje, haz clic aquí para activar tu sesión por WhatsApp
                        </a>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <>



            <div
                className='d-flex 
                justify-content-center 
                align-items-center'

            >
                <ToastContainer
                    position='top-center'
                    style={{ marginTop: 20 }}
                    onClick={() => {
                        setShowToast({ bg: '', show: false, message: '' });
                    }}
                >
                    <Toast show={showToast.show}
                        style={{
                            maxWidth: 250,
                        }}
                        bg={showToast.bg}
                        onClose={() => {
                            setShowToast({ bg: '', show: false, message: '' });
                        }}
                    >
                        <Toast.Body
                            className='text-white text-center'
                        >
                            {showToast.message}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>





            <div
                style={{
                    background: newToken ? 'transparent' : '#F89A43',
                    textAlign: 'center',

                    margin: 'auto',
                    borderRadius: 5,

                }}
                className='d-flex flex-column justify-content-center align-items-center p-3 mt-3'
            >
                {!newToken &&
                    <p>

                        {t('ingresa_numero_celular')}

                    </p>

                }

                <PhoneInput
                    labels={
                        {
                            "ZZ": "Internacional",
                            "CO": "Colombia",
                            "CR": "Costa Rica",
                            "MX": "México",
                            "US": "Estados Unidos",
                            "CL": "Chile",
                            "PE": "Perú",
                            "BR": "Brasil",
                            "DO": "República Dominicana",

                        }
                    }
                    style={{
                        fontSize: 16,
                        width: 200,
                    }}
                    countries={["MX", "DO", "US", "CO", "CR", "PE", "CL", "BR"]}
                    international
                    placeholder="Celular"
                    value={phone}
                    onChange={setPhone}
                    disabled={fetching}
                    defaultCountry={selectedCountry}
                />
                {newToken ?
                    <button
                        className="btn"
                        onClick={() =>
                            fnReactivate(true)
                        }
                        disabled={fetching}
                        style={{

                            marginTop: '1rem',
                            backgroundColor: '#00AE4C',
                            color: 'white',
                        }}
                    >
                        {fetching &&
                            <Spinner className='me-2' animation="border" size='sm' />

                        }
                        &nbsp; {t("continuar_en_whatsapp")}
                    </button>

                    :
                    <button
                        className="btn btn-primary"
                        onClick={() => fnReactivate()}
                        disabled={fetching}
                        style={{ width: '180px', marginTop: '1rem' }}
                    >
                        {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                        {t('Activar Sesión')}
                    </button>

                }

            </div >

        </>
    );
}
export default ExpiredToken;
