import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Button,
    Container,
} from 'react-bootstrap';
import Header from '@Components/Header';

import FetchingComponent from '@Components/FetchingComponent';
import AccountAddress from '@Components/AccountAddress';
import {
    useParams,
    useSearchParams,
    useNavigate
} from "react-router-dom";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

import { useDispatch, useSelector } from 'react-redux';




function ShippingSreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let token = useParams().token;
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    const sku = searchParams.get("sku");
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        api_result,
        fetching,
        cart
    } = dataReducer;


    React.useEffect(() => {
        dispatch({
            type: 'CART_OPERATION',
            payload: {
                token,
                method: 'GET',
            },

        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ALANI</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <FetchingComponent
                fetching={fetching}
                api_result={api_result}
                account={account}

                whatsappMessage="Dirección de envíos"

            >
                <Container
                    className='mt-3 mb-7'
                    style={{
                        minWidth: 350,
                        maxWidth: 400,
                    }}
                >
                    {sku !== null &&
                        <div className='mb-2'>
                            <Button
                                size='sm'
                                variant="secondary"
                                onClick={() => {

                                    navigate(`/${token}/cart`);
                                }}
                            >

                                <IoChevronBack />
                                Carrito de Compras
                            </Button>
                        </div>
                    }
                    <b>Dirección de Envío</b>
                    {!!account &&
                        <>

                            <AccountAddress
                                submitText={<>
                                    Continuar al Pago
                                    <IoChevronForward />
                                </>}
                                initialValues0={{
                                    ...account
                                }}
                                submit={({
                                    firstName,
                                    lastName,
                                    email,
                                    address1,
                                    address2,
                                    city,
                                    state,
                                    zipcode,
                                    country_code
                                }) => {


                                    dispatch({
                                        type: 'ACCOUNT_UPDATE',
                                        payload: {
                                            method: 'POST',
                                            token: token,
                                            body: {
                                                shopping_cart_id: cart.shopping_cart_id,
                                                first_name: firstName,
                                                last_name: lastName,
                                                email,
                                                address1,
                                                address2,
                                                city,
                                                state,
                                                zipcode,
                                                country_code
                                            }
                                        }
                                    });

                                    navigate(`/${token}/pay`);
                                }}
                            />
                            <Button
                                className='mt-2'
                                size='sm'
                                variant="secondary"
                                onClick={() => {

                                    navigate(`/${token}/cart`);
                                }}
                            >

                                <IoChevronBack />
                                Carrito de Compras
                            </Button>
                            <br />  <br />
                        </>
                    }
                </Container>
            </FetchingComponent>
        </>
    );
}

export default ShippingSreen;
