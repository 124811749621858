import React from 'react';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap'
import {
    useParams,
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { IoLogoWhatsapp, IoChevronBack, IoCart, IoLogOut } from "react-icons/io5";

import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import utils from '@Components/utils';

import { useTranslation, } from 'react-i18next';
const Header = () => {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars


    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account
    } = dataReducer;

    React.useEffect(() => {
        if (!cookies.alani360) return;
        if (i18n.language === 'es' && cookies.alani360 !== 'BR') return;
        if (cookies.alani360 === 'BR' && i18n.language !== 'pt') {
            i18n.changeLanguage('pt');
        }
    }, [cookies.alani360, i18n]);


    React.useEffect(() => {
        if (!account) return;
        if (!account.country) return;
        setCookie('alani360', account.country, { path: '/' });
        let phone = account?.phone?.trim();
        let first_name = account?.first_name?.trim();

        setCookie('alani360info', { phone, first_name }, { path: '/' });
    }, [account, setCookie]);

    const token = useParams().token;
    return <>
        <Navbar bg="light" expand="lg"
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 102,
            }}
        >
            <Container>

                <a
                    style={{ fontSize: '1.5rem', marginRight: '1rem' }}
                    target="_blank" rel="noreferrer" href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}`}
                >
                    <IoChevronBack />
                    <IoLogoWhatsapp />
                </a>
                <Navbar.Brand
                    style={{
                        fontSize: '1.2rem',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                        }}
                    >
                        <div

                            className='d-flex flex-row justify-content-center align-items-center me-4'
                        >
                            {!!account &&
                                <span>
                                    &nbsp;
                                    <img
                                        style={{
                                            width: 30,

                                        }}
                                        alt=""
                                        src={`/images/flags/${cookies.alani360}.png`}
                                    />
                                </span>
                            }

                        </div>
                        <img
                            style={{
                                height: 55,
                            }}
                            src="/images/bby_logo.png"
                            alt="Powered by Alani360"
                        />
                        <div
                            style={{
                                display: 'flex',
                                marginLeft: 10,
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <div style={{
                                fontSize: '0.45rem',
                                color: '#808080',
                                marginLeft: 12,
                                marginBottom: 5,
                            }}>powered by</div>
                            <img
                                style={{
                                    width: 65,
                                    marginLeft: 10,
                                    marginRight: 10,
                                    bottom: 15,
                                    position: 'absolute',
                                }}
                                src="/images/alani360_1.png"
                                alt="Powered by Alani360"
                            />
                        </div>

                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav

                        className="me-auto"

                    >
                        <LinkContainer to={`/home/${token}/`} >
                            <Nav.Link  >{t('Inicio')}</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to={`/${token}/ayuda/`} >
                            <Nav.Link className='d-flex align-items-center' >🙋‍♀️&nbsp;Ayuda</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/ofertas/${token}/`} >
                            <Nav.Link className='d-flex align-items-center' >🤑&nbsp;Ofertas</Nav.Link>
                        </LinkContainer>


                        <LinkContainer to={`/${token}/bby`} >
                            <Nav.Link  >Best&nbsp;Buy</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/marcas`} >
                            <Nav.Link  >{t('Marcas')}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/consideraciones`} >
                            <Nav.Link  >{t('Consideraciones')}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/terms`} >
                            <Nav.Link  >T&C</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/orders`} >
                            <Nav.Link  >{t('Ordenes')}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/account`} >
                            <Nav.Link  >{t('Cuenta')}</Nav.Link>
                        </LinkContainer>


                        <LinkContainer to={`/${token}/buscar`} >
                            <Nav.Link  >{t('Buscar')}</Nav.Link>
                        </LinkContainer>

                        {account?.has_open_shopping_cart === 1 &&
                            <LinkContainer to={`/${token}/cart`} >
                                <Nav.Link className='d-flex align-items-center' >{t('Carrito')} <IoCart color='blue' /></Nav.Link>
                            </LinkContainer>
                        }


                        <LinkContainer to={`/home`} >
                            <Nav.Link
                                className='d-flex align-items-center'
                            >{t('Salir')}<IoLogOut className='ms-2' color="blue" /> </Nav.Link>
                        </LinkContainer>

                        <Nav.Link
                            target="_blank"
                            rel="noreferrer"
                            href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=Ayuda`}

                            className='d-flex align-items-center'
                        ><IoLogoWhatsapp />&nbsp;{t('Contacto')}</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    </>


}

export default Header;