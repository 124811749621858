let root = process.env.REACT_APP_API

// "https://h4gqwxd22m.execute-api.us-east-1.amazonaws.com/beta" //process.env.REACT_APP_API;
const api_key = process.env.REACT_APP_API_KEY;

const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInJlc0lkIjozLCJpYXQiOjE2NzQ3NTk3NzJ9.cExPjXeVt-Pf_AWWY6VrxKkpEfU4hPGSvmJslDga6Cs";




const api = {

    ofertas: {
        fetch: ({
            token,
            offer_code,
            country_code
        }) => {


            let url = `${root}/bby/ofertas?token=${token}`;
            if (offer_code !== "") {
                url += `&offer_code=${offer_code}`;
            }
            if (country_code !== "") {
                url += `&country_code=${country_code}`;
            }
            return new Promise((resolve, reject) => {
                fetch(url)
                    .then(response => response.json())
                    .then(data => resolve(data))
                    .catch(error => reject(error));
            });
        }
    },

    reactivate: (args) => {

        return new Promise((resolve, reject) => {

            let url = `${root}/bby/reactivate`;
            let payload = args.payload;
            url = url + `?redirect=${payload.redirect}&phone=${payload.phone}&country_code=${payload.country_code}`;

            url += '&concurso=' + payload.concurso;

            const headers = new Headers({
                "Content-Type": "application/json",
                "Authorization": token,
                "x-api-key": api_key
            });

            fetch(url, {
                headers,
                method: 'POST',
                //body: JSON.stringify(args.payload.body)

            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        }
        )
    },

    invite: (args) => {

        return new Promise((resolve, reject) => {

            let url = `${root}/bby/invite`;

            let payload = args.payload;
            url = url + `?name=${payload.name}&phone=${payload.phone}&country_code=${payload.country_code}`;


            const headers = new Headers({
                "Content-Type": "application/json",
                "Authorization": token,
                "x-api-key": api_key
            });

            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args.payload.body)

            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        }
        )
    },


    search: (args) => {

        return new Promise((resolve, reject) => {

            let url = `${root}/bby/search/`;

            const headers = new Headers({
                "Content-Type": "application/json",
                "Authorization": token,
                "x-api-key": api_key
            });

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args.payload.body)

            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    //console.warn(err)
                    reject(err);
                });
        }
        )
    },


    fetch: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/${args.path}`;

            const headers = new Headers({
                "Content-Type": "application/json",
                "Authorization": token,
                "x-api-key": api_key
            });

            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    //console.warn(err)
                    reject(err);
                });
        })
    },

    order_fetch: (args) => {


        return new Promise((resolve, reject) => {

            let token = args.payload.token
            if (!!token) {
                token = token.replace('[', '').replace(']', '');
            }
            let url = `${root}/account/${token}/orders/${args.payload.order_id}`;

            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    //console.warn(err)
                    reject(err);
                });
        })
    },


    product_fetch: (args) => {


        return new Promise((resolve, reject) => {

            let url = `${root}/sku/${args.sku}/dev/?country_code=${args.country_code}&token=${args.token}&analytics=1`;

            if (args.ck_availability) {
                url += `&ck_availability=${args.ck_availability}`;
            }

            const headers = new Headers({
                "Content-Type": "application/json",
                "Authorization": token,
                "x-api-key": api_key
            });

            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    //console.warn(err)
                    reject(err);
                });
        })
    },



}



api.cart = {

    mex_payment_ebanx: (args) => {

        return new Promise((resolve, reject) => {

            let payload = args.payload;

            let token = args.payload.token
            if (!!token) {
                token = token.replace('[', '').replace(']', '');
            }
            let url = `${root}/account/${token}/cart/pay/mex_directpay`


            url = url + `?account_id=${payload.account_id}&cart_id=${payload.cart_id}&payment_type_code=${payload.payment_type_code}&documento=${payload.documentNumber}`;


            const headers = new Headers({
                "Content-Type": "application/json",
                "Authorization": token,
                "x-api-key": api_key
            });

            fetch(url, {
                headers,
                method: args.method,
                //body: JSON.stringify(args.payload.body)

            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        }
        )
    },
    operation: (args) => {

        return new Promise((resolve, reject) => {


            let url = `${root}/account/${args.payload.token}/cart`;


            if (args.cart_operation_pay) {
                url += `/pay?cart_id=${args.payload.cart_id}`;
            } else {

                if (args.payload.analytics) {
                    url += `?analytics=${args.payload.analytics}`;
                }
            }


            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);

            ////console.warn("fetch operation", url)

            fetch(url, {
                headers,
                method: args.payload.method,
                body: args.payload.body ? JSON.stringify(args.payload.body) : null
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    //console.warn(err)
                    reject(err);
                });
        })
    },

}

api.account = {
    operation: (args) => {
        return new Promise((resolve, reject) => {

            let token = args.payload.token
            if (!!token) {
                token = token.replace('[', '').replace(']', '');
            }


            let url = `${root}/account/${token}`;

            if (args.payload.orders_fetch) {
                url += `?orders=${args.payload.orders_fetch}`;
            }

            if (args.payload.route) {
                url += `/${args.payload.route}`;
            }
            if (args.payload.querystring) {
                url += `?${args.payload.querystring}`;
            }

            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: args.payload.method,
                body: args.payload.body ? JSON.stringify(args.payload.body) : null
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    //console.warn(err)
                    reject(err);
                });
        })
    },

}

export default api;