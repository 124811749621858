import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

import { CookiesProvider } from 'react-cookie';

import './i18n';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { Provider } from 'react-redux';
import store from './app/store'

import Product from '@Screens/Product';
import CustomerInfo from '@Screens/CustomerInfo';
import ShippingSreen from '@Screens/ShippingScreen';
import PaymentScreen from '@Screens/PaymentScreen';
import CartScreen from '@Screens/CartScreen';
import OrdersScreen from '@Screens/OrdersScreen';
import OrderDetails from '@Screens/Order';
import SearchResults from '@Screens/SearchResults';
import Country from '@Screens/Country';
import Search from '@Screens/Search';

import Tarifas from '@Screens/Tarifas';
import Ofertas from '@Screens/public/Ofertas';

const Home = React.lazy(() => import('@Screens/public/Home'));
const BBY = React.lazy(() => import('@Screens/public/BBY'));
const Privacy = React.lazy(() => import('@Screens/public/Privacy'));
const Brands = React.lazy(() => import('@Screens/public/Brands'));
const Terms = React.lazy(() => import('@Screens/public/Terms'));
const Restrictions = React.lazy(() => import('@Screens/public/Restrictions'));

const Contest = React.lazy(() => import('@Screens/public/Contest'));

const HomeAlone = React.lazy(() => import('@Screens/public/Home_v0'));



const Ayuda = React.lazy(() => import('@Screens/public/Ayuda'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <Provider store={store}>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
      >
        <Suspense fallback={<div
          className='d-flex flex-column justify-content-center align-items-center p-5'
          style={{ textAlign: 'center' }}>

          Best Buy powered by ALANI 360...



          <img
            style={{ width: 100, opacity: 0.5 }}
            className='mt-5'
            src='https://bby.alani360.com/images/home/bestbuy-logo-100.jpg' alt='best buy logo'
          />
        </div>}>

          <BrowserRouter>
            <Routes>


              <Route path='/concurso'
                element={<Contest />}
              />

              <Route path='/concurso/:token/reglas'
                element={<Contest
                  reglas={true}
                />}
              />
              <Route path='/concurso/:token/ok'
                element={<Contest
                  ok={true}
                />}
              />

              <Route path='/concurso/:token'
                element={<Contest />}
              />

              <Route path='/country'
                element={<Country />}
              />
              <Route path='/home_v0'
                element={<HomeAlone />}
              />

              <Route path='/'
                element={<Home />}
              />

              <Route path='/home/:token'
                element={<Home />}
              />
              <Route path='/home'
                element={<Home />}
              />
              <Route path='/bby'
                element={<BBY />}
              />
              <Route path='/privacy'
                element={<Privacy />}
              />
              <Route path='/brands'
                element={<Brands />}
              />
              <Route path='/marcas'
                element={<Brands />}
              />
              <Route path='/terms'
                element={<Terms />}
              />
              <Route path='/restricciones'
                element={<Restrictions />}
              />
              <Route path='/consideraciones'
                element={<Restrictions />}
              />


              <Route path='/search'
                element={<Search />}
              />

              <Route path='/buscar'
                element={<Search />}
              />


              <Route path='/ofertas/:token/:offer_code'
                element={<Ofertas />}
              />
              <Route path='/of/:token/:offer_code'
                element={<Ofertas />}
              />

              <Route path='/of/:token/'
                element={<Ofertas
                />}
              />
              <Route path='/ofertas/:token/'
                element={<Ofertas
                />}
              />
              <Route path='/ofertas/'
                element={<Ofertas
                />}
              />

              <Route path='/:token/ayuda/'
                element={<Ayuda
                  topic={'general'}
                />}
              />
              <Route path='/ayuda/'
                element={<Ayuda
                  topic={'general'}
                />}
              />

              <Route path='/:token/ayuda/buscar'
                element={<Ayuda
                  topic={'buscar'}
                />}
              />
              <Route path='/ayuda/buscar'
                element={<Ayuda
                  topic={'buscar'}
                />}
              />

              <Route path='/:token/buscar'
                element={<Search />}
              />

              <Route path='/:token/search'
                element={<Search />}
              />


              <Route path='/:token/bby'
                element={<BBY />}
              />
              <Route path='/:token/terms'
                element={<Terms />}
              />

              <Route path='/:token/tarifas'
                element={<Tarifas />}
              />

              <Route path='/:token/restricciones'
                element={<Restrictions />}
              />
              <Route path='/:token/consideraciones'
                element={<Restrictions />}
              />

              <Route path='/:token/sku/:sku'
                element={<Product />}
              />

              <Route path='/b/:token/:sku'
                element={<Product />}
              />

              <Route path='/:token/brands'
                element={<Brands />}
              />
              <Route path='/catalogo'
                element={<Brands />}
              />
              <Route path='/:token/catalogo'
                element={<Brands />}
              />
              <Route path='/:token/search/:search_code'
                element={<SearchResults />}
              />

              <Route path='/:token/marcas'
                element={<Brands />}
              />
              <Route path='/:token/account/'
                element={<CustomerInfo />}
              />
              <Route path='/:token/Shipping'
                element={<ShippingSreen />}
              />

              <Route path='/:token/cart'
                element={<CartScreen />}
              />
              <Route path='/:token/pay'
                element={<PaymentScreen />}
              />
              <Route path='/o/:token'
                element={<OrdersScreen />}
              />
              <Route path='/:token/orders'
                element={<OrdersScreen />}
              />


              <Route path='/:token/o/:order_id'
                element={<OrderDetails />}
              />
              <Route path='/:token/orders/:order_id'
                element={<OrderDetails />}
              />
              <Route path='/o/:token/:order_id'
                element={<OrderDetails />}
              />

              <Route
                path="*"
                element={<App />}
              />

            </Routes>
          </BrowserRouter>
        </Suspense>

      </ThemeProvider>
    </Provider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
